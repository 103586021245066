import React from "react";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { colors } from "../constants/constants";

const useStyles = makeStyles(() => ({
  app_bar: {
    background: colors.primaryColor,
    borderBottom: `1px solid ${colors.primaryLightColor}`
  },
  logo: {
    display: 'flex', 
    alignItems: 'center',
    fontWeight: "bold",
    height: 48
  }
  
}));

export default function Header() {
  const classes = useStyles();
  return (
    <header>
      <AppBar position="fixed" className={classes.app_bar} >
        <Toolbar>
          <Typography component="div">
            <div className={classes.logo}>
              <img src='/Logo.svg' alt="Logo" style={{marginRight: 8}}/>
              <span>Lendle.xyz ERC20 Faucet</span>
            </div>
          </Typography>
        </Toolbar>
      </AppBar>
    </header>
  );
}
