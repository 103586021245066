import React from "react";
import Alert from "@material-ui/lab/Alert";

export function NetworkErrorMessage({ message, dismiss }) {
  return (
    <Alert variant="filled" severity="error">
      {message}
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
        onClick={dismiss}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </Alert>
  );
}
