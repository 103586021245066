import React from "react";
import Alert from "@material-ui/lab/Alert";

export function WaitingForTransactionMessage({ txHash }) {
  return (
    <Alert variant="filled" severity="info">
      Waiting for transaction <strong>{txHash}</strong> to be mined
    </Alert>
  );
}
