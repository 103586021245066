export const colors = {
  primaryColor: "#42307D",
  primaryDarkColor: "#1B0027",
  primaryLightColor: "#9E77ED",
};

// export const maxMint = {
//     dai: 1,
//     usdc: 1,
//     usdt: 1,
//     wbtc: 0.0000003,
//     weth: 0.000005,
//     wmht: 100,
//     other: 1,
// }

export const maxMint = {
  DAI: 1000,
  USDC: 1000,
  USDT: 1000,
  WBTC: 0.03,
  WETH: 0.5,
  WMHT: 100,
  other: 1000,
};
