import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 345,
  },
  actions: {
    paddingTop: 20,
  },
}));

export function DeployERC20({ deploy }) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader
        title="Deploy ERC20"
        subheader="Allowed only for factory owner"
      />
      <CardContent>
        <form
          autoComplete="off"
          onSubmit={(event) => {
            event.preventDefault();

            const formData = new FormData(event.target);
            const name = formData.get("name");
            const symbol = formData.get("symbol");
            const decimals = formData.get("decimals");

            if (name && symbol && decimals) {
              deploy(name, symbol, decimals);
            }
          }}
        >
          <FormControl fullWidth>
            <TextField name="name" label="Name" type="text" required />
          </FormControl>
          <FormControl fullWidth>
            <TextField name="symbol" label="Symbol" type="text" required />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              name="decimals"
              label="Decimals"
              type="number"
              min={0}
              max={18}
              required
            />
          </FormControl>
          <FormControl className={classes.actions}>
            <Button variant="contained" color="primary" type="submit">
              Deploy
            </Button>
          </FormControl>
        </form>
      </CardContent>
    </Card>
  );
}
