/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import { colors } from "../constants/constants";
import { maxMint } from "../constants/constants";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 520,
    background: colors.primaryColor,
    color: "#fff",
    overflow: "hodden",
  },
  info: {
    maxWidth: 488,
    padding: "8px 16px",
    border: `1px solid ${colors.primaryLightColor}`,
    borderRadius: 16,
  },
  actions: {
    display: "flex",
    alignItems: "center",
    paddingTop: 20,
  },
  field__input: {
    marginTop: 24,
    padding: 8,
    color: "#fff",
    background: colors.primaryColor,
    border: `1px solid ${colors.primaryLightColor}`,
    borderRadius: 8,
    backgroundClip: "padding-box",
    outline: 0,
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  },
  link: {
    color: `${colors.primaryLightColor}`,
  },
  note: {
    padding: "0 16px",
    height: 16,
    fontSize: "12px",
    color: "red",
  },
}));

export const Mint = ({ mint, token, receiver, cheshireResult }) => {
  const [value, setValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [showNote, setShowNote] = useState(false);

  const handleChange = (event) => {
    const inputValue = event.target.value;
    console.log("inputValue", inputValue);
    if (!inputValue) {
      setValue("");
    }

    if (!!cheshireResult) {
      setMaxValue("");
      return setValue(inputValue);
    }

    if (!cheshireResult) {
      if (!!maxMint[token.symbol]) {
        parseFloat(inputValue) > maxMint[token.symbol] * Math.pow(10, token.decimals) ? setShowNote(true) : setShowNote(false);
        if (parseFloat(inputValue) <= maxMint[token.symbol] * Math.pow(10, token.decimals)) {
          setMaxValue(maxMint[token.symbol]);
          return setValue(inputValue);
        }
      }
      if (!maxMint[token.symbol]) {
        parseFloat(inputValue) > maxMint.other * Math.pow(10, token.decimals) ? setShowNote(true) : setShowNote(false);
        if (parseFloat(inputValue) <= maxMint.other * Math.pow(10, token.decimals)) {
          setMaxValue(maxMint.other);
          return setValue(inputValue);
        }
      }
    }
  };

  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader
        title={`Mint ${token.symbol}`}
        subheader={
          cheshireResult ? (
            <span style={{ color: "#fff" }}>Everybody can mint ERC20 tokens</span>
          ) : (
            <>
              <span style={{ color: "#fff" }}>
                Everyone can mint ERC20 tokens within the limit. To use the Lendle Faucets service without limits, you need to pass{" "}
              </span>
              <a href={`https://knowyourcat.id/credentials/${receiver}`} target="_blank" className={classes.link}>
                verification
              </a>
            </>
          )
        }
      />
      <CardContent>
        <div className={classes.info}>
          <div style={{ width: 402 }}>Contract: {token.address}</div>
          <div style={{ width: 402 }}>Receiver: {receiver}</div>
        </div>
        <form
          autoComplete="off"
          onSubmit={(event) => {
            event.preventDefault();

            const formData = new FormData(event.target);
            const amount = formData.get("amount");

            if (amount) {
              mint(amount.toString());
            }
          }}
        >
          <FormControl fullWidth>
            <input
              className={classes.field__input}
              name="amount"
              type="number"
              step="0.01"
              placeholder={`Amount of ${token.symbol}`}
              required
              value={value}
              onChange={handleChange}
              max={maxValue}
              min="0"
            />
          </FormControl>
          <div className={classes.note}>
            {showNote && (
              <>
                <span>* To use the Lendle Faucets service without limits, you need to pass </span>
                <a href={`https://knowyourcat.id/credentials/${receiver}`} target="_blank" className={classes.link}>
                  verification
                </a>
              </>
            )}
          </div>
          <FormControl className={classes.actions}>
            <Button variant="contained" color="primary" type="submit">
              Mint
            </Button>
          </FormControl>
        </form>
      </CardContent>
    </Card>
  );
};
