import React from "react";
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";

import Header from "./components/Header";
import Footer from "./components/Footer";
import { Dapp } from "./components/Dapp";

const theme = createTheme({
  overrides: {
    MuiFilledInput: {
      input: {
        paddingTop: "13px",
      },
    },
    MuiDialogActions: {
      root: {
        display: "block",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "100%",
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 40,
    minWidth: 888,
    color: '#fff',
  },
  footer_position: {
    position: "fixed",
    bottom: 0,
    width: '100%'
  }
}));

export default function App() {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Header />
        <div className={classes.content}>
          <Dapp />
        </div>
        <div className={classes.footer_position}>
          <Footer />
        </div>
        
      </div>
    </ThemeProvider>
  );
}
