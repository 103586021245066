import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import { colors } from "../constants/constants";

const useStyles = makeStyles(() => ({
  footer: {
     display: "flex",
    alignItems: "end",
    boxShadow: "none",
    background: colors.primaryDarkColor,
    borderTop: `1px solid ${colors.primaryLightColor}`
  }
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.footer}>
      <Toolbar>
        <Link
          href="http://lendle.xyz"
          target="_blank"
          rel="noopener"
        >
          <IconButton color="default" aria-label="GitHub repo" component="span">
            <img src='/git.svg' alt="Wallet"/>
          </IconButton>
        </Link>
      </Toolbar>
    </AppBar>
  );
}
