import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../constants/constants";
import { NetworkErrorMessage } from "./NetworkErrorMessage";

const useStyles = makeStyles(() => ({
  grid_margin: {
    marginTop: '30vh',
  },
  connectButton: {
    background: colors.primaryLightColor,
    color: '#fff'
  }
  
}));

export function ConnectWallet({ connectWallet, networkError, dismiss }) {
   const classes = useStyles();
  return (
    <Grid container justifyContent="center" className={classes.grid_margin}>
      <Grid item style={{ textAlign: "center" }}>
        <p>Please connect to your wallet.</p>
        <Grid item style={{ marginBottom: "12px" }}>
          {/* Metamask network should be set to Localhost:8545. */}
          {networkError && (
            <NetworkErrorMessage message={networkError} dismiss={dismiss} />
          )}
        </Grid>
        <Button
          variant="contained"
          onClick={connectWallet}
          className={classes.connectButton}
        >
          Connect Wallet
          <img src="/wallet.svg" alt="Wallet" style={{ marginLeft: 8 }} />
        </Button>
      </Grid>
    </Grid>
  );
}
