import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { ethers } from "ethers";
import axios from "axios";

import fERC20Artifact from "../contracts/fERC20.json";
import fFactoryArtifact from "../contracts/fFactory.json";
import config from "../contracts/config.json";

import { NoWalletDetected } from "./NoWalletDetected";
import { ConnectWallet } from "./ConnectWallet";
import { Loading } from "./Loading";
import { TransactionErrorMessage } from "./TransactionErrorMessage";
import { WaitingForTransactionMessage } from "./WaitingForTransactionMessage";
import { Mint } from "./Mint";
import { DeployERC20 } from "./DeployERC20";
import { colors } from "../constants/constants";

// This is an error code that indicates that the user canceled a transaction
const ERROR_CODE_TX_REJECTED_BY_USER = 4001;

export class Dapp extends React.Component {
  constructor(props) {
    super(props);

    // We store multiple things in Dapp's state.
    // You don't need to follow this pattern, but it's an useful example.
    this.initialState = {
      erc20set: undefined,
      // The user's address and balance
      selectedAddress: undefined,
      mintERC20: undefined,
      // The ID about transactions being sent, and any possible error with them
      txBeingSent: undefined,
      transactionError: undefined,
      networkError: undefined,
      isCheshireResult: undefined,
    };

    this.state = this.initialState;
  }

  render() {
    if (window.ethereum === undefined) {
      return <NoWalletDetected />;
    }

    if (!this.state.selectedAddress) {
      return (
        <ConnectWallet
          connectWallet={() => this._connectWallet()}
          networkError={this.state.networkError}
          dismiss={() => this._dismissNetworkError()}
        />
      );
    }

    if (!this.state.erc20set) {
      return <Loading />;
    }

    return (
      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", margin: 24, width: 840 }}>
        {/* {console.log('selectedAddress: ', this.state.selectedAddress, 'isCheshireResult: ', this.state.isCheshireResult)} */}
        <div className="row">
          <div className="col-12">
            <h5 style={{ padding: "0 16px" }}>
              Account: <b>{this.state.selectedAddress}</b>, ChainId: <b>{this._chainId}</b>
            </h5>
          </div>
        </div>
        <div style={{ border: `1px solid ${colors.primaryLightColor}`, borderRadius: 16, overflow: "hidden", marginBottom: 24 }}>
          <Table aria-label="simple table" style={{ padding: 0, marginBottom: "-1px" }}>
            <TableHead style={{ background: "rgba(255, 255, 255, 0.2)" }}>
              <TableRow>
                <TableCell style={{ borderBottom: `1px solid ${colors.primaryLightColor}`, color: "#fff" }}>Token</TableCell>
                <TableCell style={{ borderBottom: `1px solid ${colors.primaryLightColor}`, color: "#fff" }}>Address</TableCell>
                <TableCell style={{ borderBottom: `1px solid ${colors.primaryLightColor}`, color: "#fff" }}>Decimals</TableCell>
                <TableCell style={{ borderBottom: `1px solid ${colors.primaryLightColor}`, color: "#fff" }}>Your balance</TableCell>
                <TableCell style={{ borderBottom: `1px solid ${colors.primaryLightColor}`, color: "#fff" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.erc20set.map((token) => (
                <TableRow key={token.symbol}>
                  <TableCell component="th" scope="row" style={{ borderBottom: `1px solid ${colors.primaryLightColor}`, color: "#fff" }}>
                    {token.name} ({token.symbol})
                  </TableCell>
                  <TableCell style={{ borderBottom: `1px solid ${colors.primaryLightColor}`, color: "#fff" }}>{token.address}</TableCell>
                  <TableCell style={{ borderBottom: `1px solid ${colors.primaryLightColor}`, color: "#fff" }}>{token.decimals}</TableCell>
                  <TableCell style={{ borderBottom: `1px solid ${colors.primaryLightColor}`, color: "#fff" }}>{token.decimals}X</TableCell>
                  <TableCell style={{ borderBottom: `1px solid ${colors.primaryLightColor}`, color: "#fff" }}>
                    <Button
                      variant="text"
                      onClick={() => {
                        this.setState({ mintERC20: token });
                      }}
                      style={{ background: colors.primaryLightColor, color: "#fff" }}
                    >
                      Mint
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        <div className="row">
          <div className="col-12">
            {this.state.txBeingSent && <WaitingForTransactionMessage txHash={this.state.txBeingSent} />}

            {this.state.transactionError && (
              <TransactionErrorMessage
                message={this._getRpcErrorMessage(this.state.transactionError)}
                dismiss={() => this._dismissTransactionError()}
              />
            )}
          </div>
        </div>

        {this.state.selectedAddress.toLowerCase() === this._owner.toLowerCase() && (
          <DeployERC20 deploy={(name, symbol, decimals) => this._deployERC20(name, symbol, decimals)} />
        )}

        {this.state.mintERC20 && (
          <Dialog
            open={!!this.state.mintERC20}
            onClose={() => {
              this.setState({ mintERC20: undefined });
            }}
          >
            <Mint
              mint={(amount) => this._mintTokens(this.state.mintERC20.address, this.state.mintERC20.decimals, amount)}
              token={this.state.mintERC20}
              receiver={this.state.selectedAddress}
              cheshireResult={this.state.isCheshireResult}
            />
          </Dialog>
        )}
      </div>
    );
  }

  async _connectWallet() {
    const [selectedAddress] = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    if (!this._checkNetwork()) {
      return;
    }
    await axios
      .get(`https://api.knowyourcat.id/v1/${selectedAddress}`)
      .then((response) => {
        const credentials = response.data.credentials.filter((item) => item.issuer === "Cheshire" && !!item.results);
        const urlParams = new URLSearchParams(window.location.search);
        const paramValue = urlParams.get("supplier");
        if (paramValue === "lendle.eth") {
          this.setState({ isCheshireResult: true });
        }
        if (credentials && credentials.length) {
          this.setState({ isCheshireResult: true });
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    this._initialize(selectedAddress);

    window.ethereum.on("accountsChanged", ([newAddress]) => {
      if (newAddress === undefined) {
        return this._resetState();
      }

      this._initialize(newAddress);
    });

    window.ethereum.on("chainChanged", ([networkId]) => {
      this._resetState();
    });
  }

  async _initialize(userAddress) {
    this.setState({
      selectedAddress: userAddress,
    });

    await this._initializeEthers();
    await this._getERC20Tokens();
  }

  async _initializeEthers() {
    // We first initialize ethers by creating a provider using window.ethereum
    this._provider = new ethers.providers.Web3Provider(window.ethereum);
    const { chainId } = await this._provider.getNetwork();
    this._chainId = chainId;

    // Then, we initialize the contract using that provider and the token's
    // artifact. You can do this same thing with your contracts.
    this._factory = new ethers.Contract(config[chainId].fFactory, fFactoryArtifact.abi, this._provider.getSigner(0));
    this._owner = await this._factory.owner();
  }

  async _getERC20Tokens() {
    const addresses = await this._factory.getERC20Tokens();

    const set = [];
    for (const address of addresses) {
      const erc20 = new ethers.Contract(address, fERC20Artifact.abi, this._provider.getSigner(0));
      set.push({
        address,
        name: await erc20.name(),
        symbol: await erc20.symbol(),
        decimals: await erc20.decimals(),
        balance: await erc20.balanceOf(this.state.selectedAddress),
      });
    }

    this.setState({ erc20set: set });
  }

  async _mintTokens(address, decimals, amount) {
    try {
      this._dismissTransactionError();
      const _amount = ethers.utils.parseUnits(amount, decimals);
      const erc20 = new ethers.Contract(address, fERC20Artifact.abi, this._provider.getSigner(0));
      const tx = await erc20.mint(this.state.selectedAddress, _amount);
      this.setState({ txBeingSent: tx.hash });

      // We use .wait() to wait for the transaction to be mined. This method
      // returns the transaction's receipt.
      const receipt = await tx.wait();

      // The receipt, contains a status flag, which is 0 to indicate an error.
      if (receipt.status === 0) {
        // We can't know the exact error that made the transaction fail when it
        // was mined, so we throw this generic one.
        throw new Error("Transaction failed");
      }
    } catch (error) {
      // We check the error code to see if this error was produced because the
      // user rejected a tx. If that's the case, we do nothing.
      if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
        return;
      }

      // Other errors are logged and stored in the Dapp's state. This is used to
      // show them to the user, and for debugging.
      console.error(error);
      this.setState({ transactionError: error });
    } finally {
      // If we leave the try/catch, we aren't sending a tx anymore, so we clear
      // this part of the state.
      this.setState({ txBeingSent: undefined });
    }
  }

  async _deployERC20(name, symbol, decimals) {
    try {
      // If a transaction fails, we save that error in the component's state.
      // We only save one such error, so before sending a second transaction, we
      // clear it.
      this._dismissTransactionError();

      // We send the transaction, and save its hash in the Dapp's state. This
      // way we can indicate that we are waiting for it to be mined.
      const tx = await this._factory.deployERC20(name, symbol, decimals);
      this.setState({ txBeingSent: tx.hash });

      // We use .wait() to wait for the transaction to be mined. This method
      // returns the transaction's receipt.
      const receipt = await tx.wait();

      // The receipt, contains a status flag, which is 0 to indicate an error.
      if (receipt.status === 0) {
        // We can't know the exact error that made the transaction fail when it
        // was mined, so we throw this generic one.
        throw new Error("Transaction failed");
      }

      // If we got here, the transaction was successful, so you may want to
      // update your state. Here, we update the user's balance.
      // await this._updateBalance();
    } catch (error) {
      // We check the error code to see if this error was produced because the
      // user rejected a tx. If that's the case, we do nothing.
      if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
        return;
      }

      // Other errors are logged and stored in the Dapp's state. This is used to
      // show them to the user, and for debugging.
      console.error(error);
      this.setState({ transactionError: error });
    } finally {
      // If we leave the try/catch, we aren't sending a tx anymore, so we clear
      // this part of the state.
      this.setState({ txBeingSent: undefined });
    }
  }

  // This method just clears part of the state.
  _dismissTransactionError() {
    this.setState({ transactionError: undefined });
  }

  // This method just clears part of the state.
  _dismissNetworkError() {
    this.setState({ networkError: undefined });
  }

  // This is an utility method that turns an RPC error into a human readable
  // message.
  _getRpcErrorMessage(error) {
    if (error.data) {
      return error.data.message;
    }

    return error.message;
  }

  _resetState() {
    this.setState(this.initialState);
  }

  _checkNetwork() {
    if (Object.keys(config).includes(window.ethereum.networkVersion)) {
      return true;
    }

    this.setState({
      networkError: `The chainId ${
        window.ethereum.networkVersion
        } is not supported. 
      Expected networks: ${Object.keys(config)
        .map((n) => n)
        .join(", ")} `,
    });

    return false;
  }
}
